import { combineReducers, configureStore, } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { api } from './queries/base';
import { setupListeners } from "@reduxjs/toolkit/query";
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { user } from './user-slice';
import { emailTemplate } from "./email-template";
import { userSubscription } from './user-subscription';
import { fieldMemory } from "./field-memory";
import { template } from "./app-template";
import { app } from "./app-slice";
import { jobSearch} from './job-search';
import { designer } from './designer';
import { designerControls } from "./designer-controls";
import { defaultFormDataSlice } from "./default-form-data-slice";
import { resumeScoreFeedBackReducer } from "./resume-score-feedbacks";
import { version } from "os";

// Combine reducers with any slices you might have. Ensure api is correctly configured.
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app: app,
  template: template,
  user: user,
  emailTemplate: emailTemplate,
  userSubscription: userSubscription,
  jobSearch: jobSearch,
  fieldMemory: fieldMemory,
  designer: designer,
  designerControls: designerControls,
  defaultFormData: defaultFormDataSlice,
  resumeScore: resumeScoreFeedBackReducer
});

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
  version: 1,
  whitelist: ["template", "jobSearch", "app", "user", "emailTemplate", "fieldMemory", "designer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      // @ts-ignore
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(api.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer> & PersistPartial;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export const store = setupStore();



export const persistor = persistStore(store);
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// This line is crucial as it enables refetching capabilities on network reconnection and more
setupListeners(store.dispatch);