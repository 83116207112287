import { createSlice } from "@reduxjs/toolkit";
import { JobPostParams, jobPostQuery, JobPostResponse } from "./queries/job-post-query";

export interface JobSearchSlice {
    jobSearchParam?: JobPostParams;
    jobSearchResponse?: JobPostResponse[]
}

const initialState: JobSearchSlice = {

};

const jobSearchSlice = createSlice({
    name: "job-search",
    initialState: initialState,
    reducers: {

    },
  extraReducers: (builder) => {
    builder.addMatcher(jobPostQuery.endpoints.searchJobPost.matchFulfilled, (state, action) => {
      state.jobSearchParam  = action.meta.arg.originalArgs;
      state.jobSearchResponse = action.payload;
    })
  }
});

export const jobSearch = jobSearchSlice.reducer;
export const { } = jobSearchSlice.actions;