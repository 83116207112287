import { createAction } from '@reduxjs/toolkit';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { getCookie } from '../../utils/helper';

interface FirebaseUerInfo {
    accessToken: string;
}

export interface SliceUser {
    userInfo: FirebaseUerInfo
}

export interface RtkQueryStore {
    user?: SliceUser;
}

export const userLogoutAction = createAction('user-logout-custom-401-action');

const rawBaseQuery = (baseUrl: string) =>
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const { user } = (getState() as RtkQueryStore);
            if (user.userInfo && user.userInfo && user.userInfo.accessToken)
                headers.set('authorization', `Bearer ${user.userInfo.accessToken}`)

            const csrfToken = getCookie('XSRF-TOKEN');
            headers.set('X-CSRF-Token', csrfToken)
            return headers;
        }
    });

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (parameters, api, extraOptions) => {
    const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
    if (!baseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'No App Backend API Host configurations found'
            }
        };
    }
    const result = await rawBaseQuery(baseUrl)(parameters, api, extraOptions);

    if (result.error && result.error.status == 401) {
        const event = new CustomEvent('show-login-form', { detail: { } });
        window.dispatchEvent(event);
    }
    return result;
};

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
    /**
     * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
     */
    baseQuery: baseQueryWithRetry,
    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     */
    tagTypes: [],
    /**
     * This api has endpoints injected in adjacent files,
     * which is why no endpoints are shown below.
     * If you want all endpoints defined in the same file, they could be included here instead
     */
    endpoints: () => ({})
});
