import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userSubscriptionQuery } from "./queries/user-subscription-query";
import { aiServicesQuery } from "./queries/ai-services-query";
import { emailTemplateQuery } from "./queries/email-template-query";
import { userQuery } from "./queries/user-query";

export enum SUBSCRIPTION_TYPE {
  CHAT_GPT_SUBSCRIPTION = "gpt-3.5-turbo",
  ACCOUNT_SUBSCRIPTION = "account-subscription",
  FREE_TRIAL = "free-trial",
  UPGRADE_ACCOUNT = 'upgrade-account',
  DOCUMENT_LIMIT_INCREASE = "document-limit-increase"
}

export interface SubscriptionType {
  type: SUBSCRIPTION_TYPE;
  minimumAmount: number;
  currency: string;
  pricePerToken: number;
  tokenYouWillGet: number;
  upgradeForMonth?: number;
  documentsYouGet?: number;
}

export interface UserSubscription {
  type: string,
  chatGptEngineTokenLimit: number;
  createdAt: Date;
  expiryDate: Date;
  updatedAt: Date;
  email_limit?: number;
  expiary
  _id?: string;
}

export interface UserSubscriptionSlice {
  showGptSubscriptionModal: boolean;
  showDocumentIncreaseModal: boolean;
  showAccountSubscriptionModal: boolean;
  userSubscription?: UserSubscription;
  showAccountUpgradeModal: boolean;
  subscriptionType?: SubscriptionType;
}

const initialState: UserSubscriptionSlice = {
  showGptSubscriptionModal: false,
  showDocumentIncreaseModal: false,
  showAccountSubscriptionModal: false,
  showAccountUpgradeModal: false
};

const handleSubscriptionError = (state, action) => {

  const data = action.payload?.data;
  state.showAccountSubscriptionModal = false;
  state.showGptSubscriptionModal = false;
  state.showDocumentIncreaseModal = false;
  state.showAccountUpgradeModal = false;

  if (data?.subscriptionType) {
    state.subscriptionType = data.subscriptionType;
  }

  state.showAccountSubscriptionModal = !!data?.showAccountSubscriptionModal;
  state.showGptSubscriptionModal = !!data?.showGptSubscriptionModal;
  state.showDocumentIncreaseModal = !!data?.showDocumentIncreaseModal;

};


const userSubscriptionSlice = createSlice({
  name: "user-subscription",
  initialState,
  reducers: {
    toggleGptSubscriptionModal: (state) => {
      state.showGptSubscriptionModal = !state.showGptSubscriptionModal;
    },
    toggleAccountSubscriptionModal: (state, action: PayloadAction<boolean>) => {
      state.showAccountSubscriptionModal = action.payload;
    },
    toggleDocumentIncreaseModal: (state, action: PayloadAction<boolean>) => {
      state.showDocumentIncreaseModal = action.payload;
    },
    toggleUpgradeAccountModal: (state, action: PayloadAction<boolean>) => {
      state.showAccountUpgradeModal = action.payload;
    },
    setSubscriptionType: (state, data) => {
      state.subscriptionType = data.payload;
    },
    openGptSubscriptionModal: (state, data: PayloadAction<any>) => {
      handleSubscriptionError(state, data)
    }
  },
  extraReducers: (builder) => {
    builder
    builder
      .addMatcher(userSubscriptionQuery.endpoints.getUserGptSubscription.matchRejected, handleSubscriptionError)
      .addMatcher(aiServicesQuery.endpoints.generateResumeFromJd.matchRejected, handleSubscriptionError)
      .addMatcher(aiServicesQuery.endpoints.resumeScore.matchRejected, handleSubscriptionError)
      .addMatcher(aiServicesQuery.endpoints.improveResume.matchRejected, handleSubscriptionError)
      .addMatcher(aiServicesQuery.endpoints.generateCoverLetterFromJd.matchRejected, handleSubscriptionError)
      .addMatcher(aiServicesQuery.endpoints.generateFeedBack.matchRejected, handleSubscriptionError)
      .addMatcher(emailTemplateQuery.endpoints.generateAiContent.matchRejected, handleSubscriptionError)
      .addMatcher(userQuery.endpoints.createNewDocument.matchRejected, handleSubscriptionError)
      .addMatcher(aiServicesQuery.endpoints.generateAiFeedback.matchRejected, handleSubscriptionError)
      .addMatcher(userSubscriptionQuery.endpoints.getUserSubscription.matchFulfilled, (state, action) => {
        state.userSubscription = action.payload.userSubscription;
      })

      .addMatcher(userSubscriptionQuery.endpoints.getUserSubscription.matchFulfilled, (state, action) => {
        state.userSubscription = action.payload.userSubscription;
      })
  },
});

export const userSubscription = userSubscriptionSlice.reducer;
export const { toggleDocumentIncreaseModal, openGptSubscriptionModal, toggleGptSubscriptionModal, toggleUpgradeAccountModal, setSubscriptionType, toggleAccountSubscriptionModal } = userSubscriptionSlice.actions;
