import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DefaultFormDataProperties {
    firstName?: string;
    jobDescription?: string;
    coverLettToAddress?: string;
    coverLettToName?: string;
    coverLettToEmail?: string;
    email?: string;
    address?: string;
    phone?: string;
    professionalTitle?: any;
}

export interface DefaultFormData {
    formData?: DefaultFormDataProperties;
}

const initialState: DefaultFormData = {
    
};

const defaultFormData = createSlice({
    name: "default-form-data",
    initialState: initialState,
    reducers: {
        setDefaultUserName: (state, action: PayloadAction<DefaultFormDataProperties>) => {
            state.formData = {
                ...action.payload
            }
        }
    }
});

export const defaultFormDataSlice = defaultFormData.reducer;
export const { setDefaultUserName } = defaultFormData.actions;