import { HiddenSectionProperties } from "../redux/toolbar-slice";
import { notification } from 'antd';

export function loadScript(src: string) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
export function getMonthName(date: Date) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[new Date(date).getMonth()];
}

export const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout | null = null;
  return function executedFunction(...args: any) {
    clearTimeout(timeout!);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const generateUniqueId = () => {
  return `${Date.now()}-${Math.floor(Math.random() * 1000000)}`;
};


export const isEmailValid = (email) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i.test(email);
};

export const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    placement: 'topRight',  // Change position if needed
    duration: 4,            // Duration of notification in seconds
    style: {
      width: '300px',
      color: '#fff',               // White text
    },
  });
};

export const convertCssStringToReactStyle = (cssString: string) => {
  const reactStyle = {};

  if (typeof cssString === 'string') {
    // Remove any potential wrapping like 'class=...' if present
    cssString = cssString.replace(/.*?["']([^"']*)["']/, "$1");

    // Split into individual styles
    cssString.split(';').forEach(style => {
      if (style.trim()) {
        // Split into key and value
        const [key, value] = style.split(':');
        if (key && value) {
          // Convert key from kebab-case to camelCase
          const formattedKey = key.trim().replace(/-([a-z])/g, (match, char) => char.toUpperCase());
          // Add to the style object
          reactStyle[formattedKey] = value.trim();
        }
      }
    });

  }
  return reactStyle;

}


export const dateToDisplayString = (dateString: string): string => {
  // Creating a Date object from the input date string
  const date = new Date(dateString);

  // Extracting the day, month, and year from the date object
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
  const year = date.getFullYear();

  // Padding the day with a leading zero if necessary and returning the formatted string
  return `${day.toString().padStart(2, '0')} ${month} ${year}`;
}

export const filterResumes = (resumes, searchTerm) => {

  if (!searchTerm) return resumes;

  const lowercasedTerm = searchTerm.toLowerCase();

  return resumes.filter((resume) => {
    // Check various properties for a match; add more as needed
    return (
      resume.formData?.basicInfo?.firstName?.toLowerCase().includes(lowercasedTerm) ||
      resume.formData?.basicInfo?.lastName?.toLowerCase().includes(lowercasedTerm) ||
      resume.formData?.basicInfo?.email?.toLowerCase().includes(lowercasedTerm)
    );
  });
};

export const getDateParsed = (item: any, fieldName: string, fieldValue: any) => {
  if (["startDateMonth", "startDateYear", "endDateMonth", "endDateYear", "present"].includes(fieldName)) {


    // Determine new values based on which field was changed
    const startDateMonth = fieldName === "startDateMonth" ? fieldValue : item.startDateMonth || '';
    const startDateYear = fieldName === "startDateYear" ? fieldValue : item.startDateYear || '';
    const endDateMonth = fieldName === "endDateMonth" ? fieldValue : item.endDateMonth || '';
    const endDateYear = fieldName === "endDateYear" ? fieldValue : item.endDateYear || '';


    if (!startDateMonth && !endDateMonth && startDateYear && endDateYear) {
      return `${startDateYear} - ${endDateYear}`;
    }

    // Validate the month and year (month 1-12, year after 1900)
    const isValidDate = (month, year) => month >= 1 && month <= 12 && year >= 1900;

    // Helper to format date
    const formatDate = (month, year) => {
      return isValidDate(month, year) ? `${month.toString().padStart(2, '0')}-${year}` : undefined;
    };

    // Format start and end dates
    const formattedStartDate = formatDate(startDateMonth, startDateYear);
    const formattedEndDate = formatDate(endDateMonth, endDateYear);

    // Determine the return value based on presence of dates
    if (fieldName === "present" && fieldValue === '1') {
      return formattedStartDate ? `${formattedStartDate} - Present` : "Present";
    } else {
      if (formattedStartDate && formattedEndDate) {
        return `${formattedStartDate} - ${formattedEndDate}`;
      } else {
        return formattedStartDate || formattedEndDate || "Not specified";
      }
    }
  }

  return item.date; // Return existing date if not a date-related change
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export const getHiddenItems = () => {

  const hiddenElements = document.querySelectorAll('.form-hidden');
  const items: HiddenSectionProperties[] = [];

  hiddenElements.forEach((element) => {

    const headerElement = element.querySelector('[data-section-header]');
    const sectionPropertyName = headerElement.attributes.getNamedItem('data-section-header').nodeValue

    if (headerElement && sectionPropertyName) {

      items.push({
        title: headerElement.firstChild.textContent,
        sectionPropertyName
      });

    }
  });

  return items;
}

export const isEnumValue = (value: string, enumObj: any): boolean => {
  return Object.values(enumObj).includes(value);
}


export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const isValidSection = (requiredFields, sectionName, dataArray) => {
  // Helper function to convert index to ordinal (1 -> "1st", 2 -> "2nd", etc.)
  const toOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  // Check if the section name is valid
  if (!requiredFields[sectionName]) {
    throw new Error("Invalid section name");
  }

  // Get the required fields for the section
  const fields = requiredFields[sectionName];

  // Array to hold validation errors
  let errors = [];

  // Check each item in the data array
  dataArray.forEach((item, index) => {
    fields.forEach((field) => {
      // If any required field is missing or empty, push an error message
      if (!item[field] || item[field].trim() === "") {
        const ordinal = toOrdinal(index + 1); // Convert index to ordinal
        errors.push({
          section: sectionName,
          itemIndex: index,
          field: field,
          message: `Field "${field}" is missing or empty in the ${ordinal} section.`,
        });
      }
    });
  });

  // If there are any errors, return them, otherwise return true for valid data
  return errors.length > 0 ? errors : true;
};

export const constructProfessionalMessage = (message, yourName) => {
  const header = "Dear Hiring Manager,\n\n";
  const closing = `\n\nSincerely,\n${yourName}`;

  // Construct the full message
  const fullMessage = `${header}${message}${closing}`;

  return fullMessage;
}

export const isProfessionalMessageFormatted = (message, yourName) => {
  const headerPattern = /^Dear Hiring Manager,\n\n/;
  const closingPattern = new RegExp(`\n\nSincerely,\n${yourName}$`);

  // Check if the message starts with the header pattern and ends with the closing pattern
  return headerPattern.test(message) && closingPattern.test(message);
}

const phoneNumberPatterns = {
  IN: /^(\+91)?[6789]\d{9}$/, // India
  UK: /^(\+44)?(7\d{9})$/,    // UK
  US: /^(\+1)?\d{10}$/,       // US
  EU: /^(\+3\d{2}|\+4\d{2})?\d{8,12}$/, // Europe (general, covers most European countries)
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  const phonePatterns = Object.values(phoneNumberPatterns);
  return phonePatterns.some((pattern) => pattern.test(phoneNumber));
};


// Function to convert camelCase to Title Case
export const camelCaseToTitle = (camelCase) => {
  if (!camelCase) {
    return ''
  }
  return camelCase
    .replace(/([A-Z])/g, ' $1') // Add space before each capital letter
    .replace(/^./, function (str) { return str.toUpperCase(); }); // Capitalize the first letter
}