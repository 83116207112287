import "../styles/globals.scss";
import type { AppProps } from "next/app";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../redux/store";
import { CookieConsentDialog } from "../components/cookie-consent";
import Head from 'next/head';

function MyApp({ Component, pageProps }: AppProps) {


  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

        <Head>
        <title>AI-Powered Resume Builder | Create Professional Resumes & Cover Letters</title>
          <meta name="description" content="Create a professional resume in minutes with our AI-powered resume builder. Choose from ATS-friendly templates, generate personalized cover letters, and optimize your resume for free." />
          <meta name="keywords" content="resume builder, free resume templates, premium resume templates, ATS resume, AI-powered resume builder, cover letter generator, resume scoring, professional resume, job search, resume optimization" />
          <meta name="author" content={process.env.NEXT_PUBLIC_APP_TITLE} />
          <meta property="og:title" content="AI-Powered Resume Builder | NeuraCV" />
          <meta property="og:description" content="Create professional resumes with our AI-powered resume builder." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.neuracv.com" />
          <meta property="og:image" content="/og-image.png" />
          <link rel="canonical" href={`${process.env.NEXT_PUBLIC_APP_DOMAIN_URL}`} />
        </Head>

        <Component {...pageProps} />
        <CookieConsentDialog />

      </PersistGate>
    </Provider>
  );
}

export default MyApp;