import { UserFormData } from '../../store/models/formData';
import { SubscriptionType, UserSubscription } from '../user-subscription';
import { api } from './base';

export interface EmailTemplateGenerationParam {
    generateContent: string;
    formData?: UserFormData;
    parameters?: any
}

export interface EmailTemplate {
    subject?: string;
    email_content?: string;
    resume_title?: string;
    mailMessage?: string
}


export interface SendMailParameters {
    subject: string;
    email_content: string;
    email_to: string[];
    documentId?: string;
    email_from: string;
}


export interface UserSubscriptionStatusResponse {
    userSubscription?: UserSubscription;
    subscriptionType: SubscriptionType;
    data?: string;
    success?: boolean;
    parsedResponse?: any;
    message: string;
}

export interface UserAppliedJobResponse {
    email_from: string;
    email_to: string;
    subject: string;
    email_content: string;
    document_id: string;
}

export const emailTemplateQuery = api.injectEndpoints({
    endpoints: (builder) => ({
        sendEmail: builder.mutation<UserSubscriptionStatusResponse, SendMailParameters>({
            query: (data) => ({
                url: `/mail/send`,
                method: "POST",
                body: data
            })
        }),
        generateAiContent: builder.mutation<UserSubscriptionStatusResponse, EmailTemplateGenerationParam>({
            query: (data) => ({
                url: `/ai-services/generate_ai_content`,
                method: "POST",
                body: data
            })
        }),
        getUserJobEmails: builder.mutation<UserAppliedJobResponse[], void>({
            query: () => ({
                url: `/mail/get-job-emails`,
                method: "POST"
            })
        }),
    })
})

export const { useSendEmailMutation, useGenerateAiContentMutation, useGetUserJobEmailsMutation } = emailTemplateQuery;