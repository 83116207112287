import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { userQuery } from "./queries/user-query";
import { UserActivityResponse, userDashboardQuery } from "./queries/user-dashboard-query";

export interface UserSlice {
  logined: boolean;
  userInfo: any;
  initialized: boolean;
  userActivity?: UserActivityResponse
}

const initialState: UserSlice = {
  logined: false,
  userInfo: null,
  initialized: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    logoutUser: (state) => {
      state.initialized = true;
      state.userInfo = null;
      state.logined = false;
    },
    setLogined: (state, action: PayloadAction<any>) => {
      state.initialized = true;
      
      state.userInfo = {
        displayName: action.payload.displayName,
        email: action.payload.email,
        photoURL: action.payload.photoURL,
        uid: action.payload.uid,
        accessToken: action.payload.accessToken,
        user_type: action.payload.user_type ?  action.payload.user_type : state.userInfo.user_type || 'student'
      };
      state.logined = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(userQuery.endpoints.deleteUserProfile.matchFulfilled, (state, action) => {
      state.initialized = true;
      state.userInfo = null;
      state.logined = false;
    })
    builder.addMatcher( userDashboardQuery.endpoints.getUserActivityAndSubscription.matchFulfilled, (state, action) => {
      state.userActivity = action.payload;
    })
  }
});

export const user = userSlice.reducer;
export const { logoutUser, setLogined } = userSlice.actions;