import { EmailTemplate,  } from "./queries/email-template-query";
import { createSlice } from "@reduxjs/toolkit";

export interface ResumeTemplateSlice {
    emailTemplate?: EmailTemplate
}

const initialState: ResumeTemplateSlice = {};

const emailTemplateSlice = createSlice({
  name: "resume-template",
  initialState: initialState,
  reducers: {
    
  }
});

export const emailTemplate = emailTemplateSlice.reducer;
export const {  } = emailTemplateSlice.actions;