import { SectionProperties } from "../components/types/editor.type";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface HiddenSectionProperties { 
    title: string;
    sectionPropertyName: string
  }

export interface DesignerTemplateSlice {
    sectionHeaderSelected?: SectionProperties
}

const initialState: DesignerTemplateSlice = {

};

const designerControllerSlice = createSlice({
    name: "designer-controls",
    initialState: initialState,
    reducers: {
        setSectionProperties: (state, action: PayloadAction<SectionProperties>) => {
            state.sectionHeaderSelected = action.payload
        }
    }
});

export const designerControls = designerControllerSlice.reducer;
export const {
    setSectionProperties
} = designerControllerSlice.actions;