import { api } from './base';
import { SectionStructure, TemplateConfig, UserFormData } from "../../store/models/formData";

export interface TemplateStyle {
    [key: string]: any;
}

export interface UserResume {
    _id: string;
    user_id?: string;
    status: boolean;
    formData: UserFormData,
    templateConfig: TemplateConfig;
    title: string;
    syncStatus?: string,
    last_modified?: string;
    created_at?: string;
    document_type?: 'cover-letter' | 'resume';
}

export interface SyncResumeData {
    resumes: UserResume[];
}

export interface TemplateHtmlData {
    template: string
}


// Define the interface for color set properties
export interface TemplateColorSet {
    font: string;
    background: string;
    otherTexts: string;
    title: string;
    style?: TemplateStyle;
    id: string;
}

export interface PageSize {
    width: number;
    height: number;
}

// Define the interface for each template item
export interface AppTemplate {
    id: any;
    title: string;
    category: string;
    defaultColorSetId: string;
    description: string;
    supportingColorSetIds: string[];
    layoutSectionStructure?: SectionStructure[];
}

export interface AppTemplateResponse {
    appTemplates: AppTemplate[];
    colorSets: TemplateColorSet[];
    reSketch: boolean;
    templateVersion: string;
}

export const templateQuery = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserResumes: builder.mutation<UserResume[], void>({
            query: () => ({
                url: `/users/get_resumes`,
                method: "POST",
            })
        }),
        getAppTemplates: builder.mutation<AppTemplateResponse, void>({
            query: () => ({
                url: `/template/get-app-templates`,
                method: "POST",
            })
        }),
        syncResume: builder.mutation<void, SyncResumeData>({
            query: (data) => ({
                url: `/users/sync_resume`,
                method: "POST",
                body: data
            })
        }),
        getSelectedTemplateHtml: builder.mutation<TemplateHtmlData, string>({
            query: (selectedTemplateId,) => ({
                url: `/template/get-template`,
                method: "POST",
                body: {
                    selectedTemplateId
                }
            })
        })
    })
})

export const { useGetUserResumesMutation, useSyncResumeMutation, useGetSelectedTemplateHtmlMutation, useGetAppTemplatesMutation } = templateQuery;