import { UserSubscription } from '../user-subscription';
import { api } from './base';

export interface UserActivityLinks {
    link: string;
    title: string;
    _id: string;
    description: string;
    created_at: Date;
}

export interface UserActivity {
    matchingJobs: number;
    totalJobsApplied: number;
    profileLookups: number;
    documentCount: number;
    updatedAt: Date;
    activities: UserActivityLinks[],
}

export interface DocumentCount {
    coverLetterCount: number;
    resumeCount: number;
}

export interface UserActivityResponse {
    userSubscription: UserSubscription;
    userActivity: UserActivity;
    documentCount: DocumentCount;
    jobMatchingCount: number;
    appliedJobCount: number;
}

export const userDashboardQuery = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserActivityAndSubscription: builder.mutation<UserActivityResponse, void>({
            query: () => ({
                url: `/dashboard/activity`,
                method: "POST"
            })
        })
    })
})

export const { useGetUserActivityAndSubscriptionMutation } = userDashboardQuery;