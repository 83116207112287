import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface FieldMemory {
    fieldId: string;
    config_type: 'date-picker-mode' | string;
    config_value: string;
}

interface FieldMemoryState {
    cachedConfigurations: FieldMemory[]
}

const initialState: FieldMemoryState = {
    cachedConfigurations: []
};

const fieldMemorySlice = createSlice({
    name: "field-memory",
    initialState,
    reducers: {
        setFieldConfiguration: (state, action: PayloadAction<FieldMemory>) => {

            const fieldMemory = [...state.cachedConfigurations];
            const filtered = fieldMemory.find((s) => s.fieldId === action.payload.fieldId && s.config_type === action.payload.config_type);

            if (filtered) {
                filtered.config_value = action.payload.config_value
            } else {
                fieldMemory.push(action.payload);
            }

            state.cachedConfigurations = [...fieldMemory]

        }
    }
});

export const fieldMemory = fieldMemorySlice.reducer;
export const { setFieldConfiguration } = fieldMemorySlice.actions;
