// src/constants/urls.ts
export const URLS = {
    AUTH_LOGIN: '/api/auth/google',
    HOME: '/',
    HOW_TO_WRITE_COVER_LETTER: '/blog/how-to-write-a-cover-letter',
    HOW_TO_OPTMIZE_YOUR_RESUME: '/blog/how-to-optimize-your-resume',
    TOP_RESUME_MISTAKES: '/blog/top-resume-mistakes',
    HOW_TO_WRITE_ATS_RESUME: '/blog/how-to-write-ats-resume',
    ATS_RESUME_SCORE: '/blog/ats-resume-score',
    AI_POWERED_LIVE_DESIGNER: '/blog/ai-powered-live-designer',
    RESUME_TEMPLATE_CATEGORIES: '/blog/resume-template-categories',
    CHATGPT_COVER_LETTER: '/blog/use-chatgpt-to-generate-a-cover-letter',
    RESUME_COVER_LETTER_DIFFERENCE: '/blog/resume-coverletter-difference',
    COVER_LETTER_SAMPLES: '/blog/cover-letter-examples',
    CV_TEMPLATES : '/cv-templates',
    RESUME_BUILDER_WITHOUT_WATERMARK: 'resume-builder-without-watermark',
    USER_PROFILE: '/user-profile',
    PRICING: '/pricing',
    FEEDBACK: '/feedback',
    DESIGNER_WINDOW: '/design',
    LOGIN: '/login',
    BLOG: '/blog',
    VIDEOS: '/blog/videos',
    REGISTER: '/register',
    SIGNUP: '/signup',
    JD_TO_CV: '/document/jd-to-cv',
    DASHBOARD: '/user/dashboard',
    COVER_LETTER: '/document/cover-letter',
    RESUME_SCORE_SCAN: '/document/cv-score',
    CUSTOMER_SUPPORT: '/customer-support',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    FAQ: '/faq',
    COOKIE: '/legal/cookie',
    VIDEO_HOW_TO_OPTMIZE_RESUME: 'https://www.youtube.com/embed/zhg3ccwnG-4?si=LZ-3TNLPGqngOIIl',
    VIDEO_HOW_TO_WRITE_COVER_LETTER: 'https://www.youtube.com/embed/QPGgpiUMKNs?si=fYIgJYYC4HcwH1iB',
    VIDEO_HOW_TO_WRITE_ATS_RESUME: 'https://www.youtube.com/embed/zhg3ccwnG-4?si=LZ-3TNLPGqngOIIl',
    VIDE_HOW_TO_FIND_RESUME_SCORE: 'https://www.youtube.com/embed/E0qlqvRHFus?si=t-tYmGVVQrWwz6Gf',
    VIDEO_UI_DEVELOPER_RESUME : 'https://www.youtube.com/embed/VcjVlaPajPs?si=UjIzXs8Z7LHMRrws'
};