import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AiFieldConfig, aiServicesQuery, ResumeModifyWorkExperience } from "./queries/ai-services-query";
import { debounce, generateUniqueId } from "../utils/helper";
import { AiFieldConfigSection, Feedback, SummaryItem } from "./dtos/feedback-element.dto";
import { UserFormData } from "../store/models/formData";

export interface KeywordsToAdd {
    hardSkills: string[],
    softSkills: string[]
}

export interface ScoreObject {
    reasons: string;
    score: number;
    suggestions: string;
    keywordsToAdd: KeywordsToAdd;
}

export interface FeedBackSuggection {
    message: string;
    sampleFix: string;
    quantifiableAchievements?: boolean;
    feedbackForPropertyName: string;
    id: string;
    isFieldSuggection: boolean
}

export interface PositiveFeedBacks {
    message: string
}


export interface FeedbackItem {
    sectionName: string;
    sectionDataIndex: number;
    sectionKeyPropertyName: string;
    sectionKey: string;
    suggections: FeedBackSuggection[];
    valueSuggections?: string[];
    scoreObject?: ScoreObject;
    positiveFeedBacks?: PositiveFeedBacks[],
    //overrides
    showInHeader: boolean;
    aiFieldSuggections?: AiFieldConfig[];
    id: string;
    status?: '' | 'added' | 'removed'
}


interface ResumeScoreFeedBackState {
    feedBacks?: FeedbackItem[];
    atsScore: number;
    jobCode: string,
    summary: SummaryItem[],
    aiFieldConfigSections: AiFieldConfigSection[]
}

interface RemoveFeedbackProps {
    feedback: FeedbackItem;
    valueToRemoveFromArray: any;
}

const initialState: ResumeScoreFeedBackState = {
    atsScore: 0,
    jobCode: '',
    summary: [],
    aiFieldConfigSections: []
};

export interface AiFiedSuggectionUpdate {
    fieldConfig: AiFieldConfig;
    suggectionParentId: string;
}

const resumeScoreFeedBack = createSlice({
    name: "resume-score-feedbacks",
    initialState,
    reducers: {
        updateFeedbackStatus: (state, action: PayloadAction<AiFiedSuggectionUpdate>) => {

            const aiFieldConfigSections = [...state.aiFieldConfigSections];
            const selectedFeedback = aiFieldConfigSections.find((feedback) => feedback.id === action.payload?.suggectionParentId);

            if (selectedFeedback) {

                const fieldConfig = selectedFeedback?.aiFieldConfigs?.find((fieldConfig) => fieldConfig?.id === action?.payload?.fieldConfig?.id);

                if(fieldConfig) {

                    fieldConfig.status = 'added';
                    state.aiFieldConfigSections = aiFieldConfigSections;
                }
               
            }


        },
        removeFeedBackValueItem: (state, action: PayloadAction<RemoveFeedbackProps>) => {

            const feedbacks = [...state.feedBacks];
            const selectedFeedback = feedbacks.find((feedback) => feedback.id === action.payload.feedback.id);

            if (selectedFeedback && selectedFeedback.valueSuggections?.length > 0) {
                selectedFeedback.valueSuggections = selectedFeedback.valueSuggections.filter((value) => value !== action?.payload?.valueToRemoveFromArray);
            }

            state.feedBacks = feedbacks;

        },
        removeFeedBackFromList: (state, action: PayloadAction<ResumeModifyWorkExperience>) => {

            let feedbacks = [...state.feedBacks];
            const selectedFeedback = feedbacks.find((feedback) => feedback.sectionKey === action.payload.sectionKey);

            if (selectedFeedback) {
                selectedFeedback.suggections = selectedFeedback.suggections.filter((suggection) => suggection.id !== action.payload.suggection_id);

                if (selectedFeedback?.suggections?.length === 0) {
                    feedbacks = feedbacks.filter((feedback) => feedback.id !== selectedFeedback?.id);
                    state.feedBacks = feedbacks;
                }
            }
            // const { sectionIndex, itemIndex } = action.payload;
            // // Ensure that state.feedBacks exists and the sectionIndex is valid
            // if (state.feedBacks && state.feedBacks[sectionIndex]?.suggections) {
            //     // Create a new array by filtering out the item at itemIndex
            //     const updatedFeedbacks = state.feedBacks[sectionIndex].suggections!.filter((_, index) => index !== itemIndex);
            //     // Update the corresponding section with the new feedback list
            //     state.feedBacks[sectionIndex] = {
            //         ...state.feedBacks[sectionIndex],
            //         suggections: updatedFeedbacks,
            //     };
            // }
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(aiServicesQuery.endpoints.resumeScore.matchFulfilled, (state, action) => {


            state.aiFieldConfigSections = [];

            if(action.payload.data?.basicInfoResult?.basicInfoFeedback?.aiSectionSuggections?.length > 0) {
                action.payload.data?.basicInfoResult?.basicInfoFeedback?.aiSectionSuggections.forEach((i) => {
                    state.aiFieldConfigSections.push({
                        title: i.title,
                        totalRate: i.totalRate,
                        id: i.id,
                        aiFieldConfigs: i.aiFieldConfigs
                    })
                });
            }

            if(action.payload.data?.skillsComparisonResult?.resumeScanFeedback?.aiSectionSuggections?.length > 0) {
                action.payload.data?.skillsComparisonResult?.resumeScanFeedback?.aiSectionSuggections.forEach((i) => {
                    state.aiFieldConfigSections.push({
                        title: i.title,
                        totalRate: i.totalRate,
                        id: i.id,
                        aiFieldConfigs: i.aiFieldConfigs
                    })
                });
            }

            if(action.payload.data?.workComparisionResult?.workExpereinceFeedBack?.aiSectionSuggections?.length > 0) {
                action.payload.data?.workComparisionResult?.workExpereinceFeedBack?.aiSectionSuggections.forEach((i) => {
                    state.aiFieldConfigSections.push({
                        title: i.title,
                        id: i.id,
                        totalRate: i.totalRate,
                        aiFieldConfigs: i.aiFieldConfigs
                    })
                });
            }


            if(action.payload.data?.educationComparisionResult?.educationFeedBack?.aiSectionSuggections?.length > 0) {
                action.payload.data?.educationComparisionResult?.educationFeedBack?.aiSectionSuggections.forEach((i) => {
                    state.aiFieldConfigSections.push({
                        title: i.title,
                        id: i.id,
                        totalRate: i.totalRate,
                        aiFieldConfigs: i.aiFieldConfigs
                    })
                });
            }

            if(action.payload.data?.keywordComparisionResult?.keywordFeedback?.aiSectionSuggections?.length > 0) {
                action.payload.data?.keywordComparisionResult?.keywordFeedback?.aiSectionSuggections.forEach((i) => {
                    state.aiFieldConfigSections.push({
                        title: i.title,
                        id: i.id,
                        totalRate: i.totalRate,
                        aiFieldConfigs: i.aiFieldConfigs
                    })
                });
            }


            if(action.payload.data?.accomplishmentsComparisonResult?.accomplishmentsFeedback?.aiSectionSuggections?.length > 0) {
                action.payload.data?.accomplishmentsComparisonResult?.accomplishmentsFeedback?.aiSectionSuggections.forEach((i) => {
                    state.aiFieldConfigSections.push({
                        title: i.title,
                        id: i.id,
                        totalRate: i.totalRate,
                        aiFieldConfigs: i.aiFieldConfigs
                    })
                });
            }

            if (action?.payload?.data?.totalScore) {
                state.atsScore = action?.payload?.data?.totalScore;
            }

            if (action.payload.data?.jobCode) {
                state.jobCode = action.payload.data?.jobCode
            }


        });
    }
});

export const resumeScoreFeedBackReducer = resumeScoreFeedBack.reducer;
export const { removeFeedBackFromList, updateFeedbackStatus, removeFeedBackValueItem } = resumeScoreFeedBack.actions;