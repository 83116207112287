import { UserFormData } from '../../store/models/formData';
import { api } from './base';

interface CreateResumeQuery {
    templateConfig: any;
    title: string;
    formData: UserFormData,
    document_type: string
}

interface CreateNewUserQuery {
    token: string;
    unique_id: string;
    email: string;
    user_type?: string;
}

export interface CreateResumeResponse {
    documentId?: string;
    success: boolean;
    message?: string
}

export interface RegisterUserParams {
    email: string;
    password: string;
    mobile_number: string;
    recruiterName: string;
    agencyName: string;
    country: string;
    city?: string;
    uid?: string;
    captchaToken: string
}

export interface RegisterUserResponse {
    success: boolean;
    message: string;
    id: string;
}

export interface LoginResponse {
    token: string;
    success: boolean;
    message: string;
    displayName: string;
    email: string;
    photoURL: string;
    uid: string;
    user_type: string
}

export interface LoginParameter {
    email: string;
    password: string;
    uid?: string;
    captchaToken?: string
}

export interface SkillParameter {
    skills: any
}

export interface SkillResponse {
    skills: []
}

export interface LoginResponse {
    message: string;
    unique_id: string
}

export const userQuery = api.injectEndpoints({
    endpoints: (builder) => ({
        registerUser: builder.mutation<RegisterUserResponse, LoginParameter>({
            query: (data) => ({
                url: `/users/register-user`,
                method: "POST",
                body: data
            })
        }),
        changePassword: builder.mutation<RegisterUserResponse, LoginParameter>({
            query: (data) => ({
                url: `/users/change-password`,
                method: "PUT",
                body: data
            })
        }),
        loginByPassword: builder.mutation<LoginResponse, LoginParameter>({
            query: (data) => ({
                url: `/users/login-with-password`,
                method: "POST",
                body: data
            })
        }),
        deleteUserProfile: builder.mutation<void, string>({
            query: (token) => ({
                url: `/users/delete-user-profile`,
                method: "POST",
                body: {
                    token
                }
            })
        }),
        createNewDocument: builder.mutation<CreateResumeResponse, CreateResumeQuery>({
            query: (data) => ({
                url: `users/document`,
                method: "POST",
                body: data
            })
        }),
        updateSkill: builder.mutation<void, SkillParameter>({
            query: (data) => ({
                url: `users/update-skills`,
                method: "POST",
                body: data
            })
        }),
        getUserSkills: builder.mutation<string[], void>({
            query: () => ({
                url: `users/get-skills`,
                method: "POST",
            })
        }),
        createNewUser: builder.mutation<LoginResponse, CreateNewUserQuery>({
            query: ({ email, unique_id, token, user_type }) => ({
                url: `users`,
                method: "POST",
                body: {
                    email,
                    unique_id,
                    token,
                    user_type
                }
            })
        }),
        deleteUserResume: builder.mutation<string, string>({
            query: (resumeId) => ({
                url: `users/delete_resume`,
                method: "POST",
                body: {
                    resumeId
                }
            })
        }),
        isDocumentCreationAllowed: builder.mutation<string, void>({
            query: () => ({
                url: `users/is_allowed`,
                method: "POST",
                body: {
                    type: 'document_creation'
                }
            })
        })
    })
})

export const { useUpdateSkillMutation, useGetUserSkillsMutation, useLoginByPasswordMutation, useRegisterUserMutation, useDeleteUserProfileMutation, useCreateNewDocumentMutation, useCreateNewUserMutation, useDeleteUserResumeMutation, useIsDocumentCreationAllowedMutation } = userQuery;