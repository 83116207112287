import { SectionStructure } from "../store/models/formData";
import { AppTemplate, TemplateColorSet, UserResume, templateQuery } from "./queries/templates-query";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface HiddenSectionProperties {
    title: string;
    sectionPropertyName: string
}

export interface DesignerTemplateSlice {
    userResumes: UserResume[];
    activeDocument?: UserResume;
    documentHtmlTemplate?: string;
    activeDocumentTemplateId?: string;
    hiddenDocumentSections: HiddenSectionProperties[],
}

const initialState: DesignerTemplateSlice = {
    userResumes: [],
    hiddenDocumentSections: []
};

const templateSlice = createSlice({
    name: "designer-template",
    initialState: initialState,
    reducers: {
        selectDesignerResume: (state, action: PayloadAction<string>) => {
            state.activeDocument = state.userResumes.find((o) => o._id === action.payload);
            if (state.activeDocument) {
                state.activeDocumentTemplateId = state.activeDocument.templateConfig?.selectedTemplateId;
            }

            state.hiddenDocumentSections = state?.activeDocument?.templateConfig?.hiddenColumns || []

        },
        toggleButtetPointView: (state) => {

            state.userResumes = state.userResumes.map((resume) =>
                resume._id === state.activeDocument?._id
                    ? {
                        ...resume,
                        templateConfig: {
                            ...resume.templateConfig,
                            showBulletBefore: resume?.templateConfig?.showBulletBefore ? false: true 
                        },
                        syncStatus: "pending"
                    }
                    : resume
            );


            state.activeDocument = {
                ...state.activeDocument,
                templateConfig: {
                    ...state.activeDocument?.templateConfig,
                    showBulletBefore: state.activeDocument?.templateConfig?.showBulletBefore ? false: true 
                },
            }
            
        },
        changeActiveDocumentTemplate: (state, action: PayloadAction<AppTemplate>) => {

            state.userResumes = state.userResumes.map((resume) =>
                resume._id === state.activeDocument?._id
                    ? {
                        ...resume,
                        templateConfig: {
                            ...resume.templateConfig,
                            selectedTemplateId: action.payload.id,
                            selectedColorSetId: action.payload.defaultColorSetId,
                            layoutSectionStructure: action.payload.layoutSectionStructure
                        },
                        syncStatus: "pending"
                    }
                    : resume
            );

            state.activeDocumentTemplateId = action.payload.id;

            state.activeDocument = {
                ...state.activeDocument,
                templateConfig: {
                    ...state?.activeDocument?.templateConfig,
                    selectedTemplateId: action.payload.id,
                    selectedColorSetId: action.payload.defaultColorSetId,
                }
            }

        },
        setActiveDocumentFormData: (state, action: PayloadAction<any>) => {
            state.userResumes = state.userResumes.map((resume) =>
                resume._id === state.activeDocument?._id
                    ? {
                        ...resume,
                        syncStatus: "pending",
                        formData: {
                            ...action.payload
                        },
                    }
                    : resume
            );
            state.activeDocument = {
                ...state.userResumes.find((o) => o._id === state.activeDocument?._id)
            }
        },
        setActiveDocumentFont: (state, action: PayloadAction<string>) => {
            state.userResumes = state.userResumes.map((resume) =>
                resume._id === state.activeDocument?._id
                    ? {
                        ...resume,
                        templateConfig: {
                            ...resume.templateConfig,
                            selectedFont: action.payload
                        },
                        syncStatus: "pending"
                    }
                    : resume
            );
            state.activeDocument = {
                ...state.userResumes.find((o) => o._id === state.activeDocument?._id)
            }
        },
        setActiveDocumentFontSize: (state, action: PayloadAction<string>) => {
            state.userResumes = state.userResumes.map((resume) =>
                resume._id === state.activeDocument?._id
                    ? {
                        ...resume,
                        templateConfig: {
                            ...resume.templateConfig,
                            fontSize: action.payload
                        },
                        syncStatus: "pending"
                    }
                    : resume
            );
            state.activeDocument = {
                ...state.userResumes.find((o) => o._id === state.activeDocument?._id)
            }
        },
        selectActiveDocumentColorSet: (state, action: PayloadAction<TemplateColorSet>) => {
            state.userResumes = state.userResumes.map((resume) =>
                resume._id === state.activeDocument?._id
                    ? {
                        ...resume,
                        templateConfig: {
                            ...resume.templateConfig,
                            selectedColorSetId: action.payload.id
                        },
                        syncStatus: "pending"
                    }
                    : resume
            );
            state.activeDocument = {
                ...state.userResumes.find((o) => o._id === state.activeDocument?._id)
            }

        },
        deleteDocument: (state, action: PayloadAction<string>) => {
            state.userResumes = [...state.userResumes.filter((o) => o._id !== action.payload)];
        },
        changeTemplateLayoutStructure: (state, action: PayloadAction<SectionStructure[]>) => {
            state.userResumes = state.userResumes.map((resume) =>
                resume._id === state.activeDocument?._id
                    ? {
                        ...resume,
                        templateConfig: {
                            ...resume.templateConfig,
                            layoutSectionStructure: action.payload
                        },
                        syncStatus: "pending"
                    }
                    : resume
            );
            state.activeDocument = {
                ...state.userResumes.find((o) => o._id === state.activeDocument?._id)
            }
        },
        setHiddenSections: (state, action: PayloadAction<HiddenSectionProperties[]>) => {
            state.hiddenDocumentSections = [...action.payload];
            state.userResumes = state.userResumes.map((resume) =>
                resume._id === state.activeDocument?._id
                    ? {
                        ...resume,
                        syncStatus: "pending",
                        templateConfig: {
                            ...resume.templateConfig,
                            hiddenColumns: [...action.payload]
                        }
                    }
                    : resume
            );
        },
        setResumeTitle: (state, action: PayloadAction<string>) => {
            state.userResumes = state.userResumes.map((resume) =>
                resume._id === state.activeDocument?._id
                    ? {
                        ...resume,
                        syncStatus: "pending",
                        title: action.payload
                    }
                    : resume
            );
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(templateQuery.endpoints.getUserResumes.matchFulfilled, (state, action) => {
            const resumes = [...action.payload].map(newItem => {
                const existingItem = state.userResumes?.find(item => item._id === newItem._id);
                if (existingItem && existingItem.syncStatus === "pending") {
                    return { ...newItem, syncStatus: "pending" };
                }
                return newItem;
            });
            state.userResumes = resumes;
        }),
            builder.addMatcher(templateQuery.endpoints.syncResume.matchFulfilled, (state) => {
                state.userResumes = state.userResumes.map(newItem => {
                    return newItem.syncStatus === "pending" ? { ...newItem, syncStatus: 'completed' } : { ...newItem }
                });
            })
    }
});

export const designer = templateSlice.reducer;
export const {
    selectDesignerResume,
    deleteDocument,
    setActiveDocumentFont,
    changeActiveDocumentTemplate,
    setActiveDocumentFormData,
    selectActiveDocumentColorSet,
    changeTemplateLayoutStructure,
    setHiddenSections,
    setResumeTitle,
    toggleButtetPointView,
    setActiveDocumentFontSize
} = templateSlice.actions;