import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FieldMemory {
    showTour: boolean
}

interface CookieConsent {
    necessoryCookie: boolean;
    functionalCookie: boolean;
    analyticalCookie: boolean;
    showCookieConcentDialog?: boolean;
    showCookieDialog: boolean;
    userUpdated?: boolean;
}

interface AppState {
    showTour: boolean;
    showFeedbackForm?: boolean;
    freeTrial: boolean;
    agreedTermsAndCondition: boolean;
    marketingCookie: CookieConsent;
}

const initialState: AppState = {
    showTour: true,
    freeTrial: false,
    showFeedbackForm: false,
    agreedTermsAndCondition: false,
    marketingCookie: {
        userUpdated: false,
        showCookieDialog: true,
        analyticalCookie: false,
        functionalCookie: false,
        necessoryCookie: false,
    }
};

const appSlice = createSlice({
    name: "app-slice",
    initialState,
    reducers: {
        toggleAgreeTermsCondition: (state) => {
            state.agreedTermsAndCondition = !state.agreedTermsAndCondition;
        },
        hideTour: (state) => {
            state.showTour = false
        },
        showTour: (state) => {
            state.showTour = true
        },
        toggleFeedbackForm: (state, action: PayloadAction<boolean>) => {
            state.showFeedbackForm = action.payload
        },
        toggleConsentModal: (state, action: PayloadAction<boolean>) => {
            state.marketingCookie = {
                ...state.marketingCookie,
                showCookieConcentDialog:  action.payload
            }
        },
        toggleConsentDialog: (state, action: PayloadAction<boolean>) => {
            state.marketingCookie = {
                ...state.marketingCookie,
                showCookieDialog: action.payload
            }
        },
        toggleConsentState: (state, action: PayloadAction<CookieConsent>) => {
            state.marketingCookie = {
                ...action.payload,
            }
        },
        endFreeTrail: (state) => {
            if (state.freeTrial) {
                state.freeTrial = false;
            }
        },
    }
});

export const app = appSlice.reducer;
export const { hideTour, toggleFeedbackForm, showTour, toggleConsentModal, toggleConsentDialog, toggleConsentState, toggleAgreeTermsCondition, endFreeTrail } = appSlice.actions;
