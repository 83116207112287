import { useEffect, useState } from "react";
import { Modal, Switch } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { toggleConsentModal, toggleConsentState } from "../../redux/app-slice";
import Link from "next/link";
import { URLS } from "../page-header/url";

export const CookieConsentDialog = () => {

    const [isFunctionalChecked, setIsFunctionalChecked] = useState(false)
    const [isAnalyticalClicked, setIsAnalyticalChecked] = useState(false);

    const [detailsVisible, setDetailsVisible] = useState(false); // Controls details panel
    const dispatch = useAppDispatch();
    const consentData = useAppSelector((state) => state.app?.marketingCookie);
    const toggleConsent = () => dispatch(toggleConsentModal(!consentData?.showCookieConcentDialog));

    useEffect(() => {

        setIsFunctionalChecked(consentData?.functionalCookie);
        setIsAnalyticalChecked(consentData?.analyticalCookie);

    }, [consentData])

    const onClickAcceptAll = () => {
        dispatch(
            toggleConsentState({
                ...consentData,
                functionalCookie: true,
                analyticalCookie: true,
                showCookieConcentDialog: false,
                showCookieDialog: false,
                userUpdated: true
            })
        );
        toggleConsent();
    };

    const onAccept = () => {
        dispatch(
            toggleConsentState({
                ...consentData,
                functionalCookie: isFunctionalChecked,
                analyticalCookie: isAnalyticalClicked,
                showCookieConcentDialog: false,
                showCookieDialog: false,
                userUpdated: true
            })
        );
        toggleConsent();
    };



    const toggleDetails = () => {
        setDetailsVisible(!detailsVisible);
    };

    return (
        <div>
            {/* Manage Cookies Button */}
            {
                consentData?.showCookieDialog && <div>
                    <div className="fixed bottom-2  left-2 right-2 md:right-8 md:left-auto max-w-md  flex gap-5 flex-col bg-blue-gradient border  p-5 bg-white shadow-lg rounded-md">
                        <p className="text-gray-700">
                            To provide a safer experience, the best content, and great
                            communication, <Link href={URLS.COOKIE}><span className="text-blue-400"> we use cookies</span> </Link>. Learn how we use them for
                            non-authenticated users.
                        </p>
                        <button
                            onClick={toggleConsent}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                        >
                            Manage Cookies
                        </button>
                    </div>
                </div>
            }

            {/* Cookie Consent Modal */}
            <Modal
                open={consentData?.showCookieConcentDialog}
                onCancel={toggleConsent}
                footer={null}
                centered
                closable={false}
                width={600}
                className="bg-white border-2  rounded-md border-cyan-500 "
                style={{ top: 20 }}
            >
                <div className="p-6 text-center bg-white">
                    <h3 className="text-2xl mb-4 font-bold">
                        Please have some cookies. They&apos;re fresh out of the oven!
                    </h3>
                    <p className="text-gray-600">No cookies are harmed in the process!</p>
                </div>

                {/* Consent Buttons */}
                <div className="p-4 flex justify-center items-center">
                    <button
                        onClick={onAccept}
                        className="mr-2 bg-gray-100 px-4 py-2 rounded-lg text-gray-500 hover:bg-gray-200 transition"
                    >
                        Accept Selected
                    </button>
                    <button
                        onClick={onClickAcceptAll}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                    >
                        Accept All
                    </button>
                </div>

                {/* Switch Options */}
                <div className="p-4">
                    <ul className="grid items-center justify-center grid-cols-3">
                        <li className="flex flex-col md:flex-row items-center gap-2 w-full">
                            <Switch checked={true} disabled />
                            <span className="text-gray-700">Necessary</span>
                        </li>
                        <li className="flex flex-col md:flex-row items-center gap-2 w-full">
                            <Switch
                                checked={isFunctionalChecked}
                                onChange={() => setIsFunctionalChecked(!isFunctionalChecked)}
                            />
                            <span className="text-gray-700">Functional</span>
                        </li>
                        <li className="flex flex-col md:flex-row items-center gap-2 w-full">
                            <Switch
                                checked={isAnalyticalClicked}
                                onChange={() => setIsAnalyticalChecked(!isAnalyticalClicked)}
                            />
                            <span className="text-gray-700">Analytical</span>
                        </li>
                    </ul>
                </div>

                {/* Details Toggle */}
                <div className="p-4 text-right">
                    <button
                        className="text-gray-600 flex items-center justify-end"
                        onClick={toggleDetails}
                    >
                        <span className="mr-2">Details</span>
                        {detailsVisible ? <UpOutlined /> : <DownOutlined />}
                    </button>
                </div>

                {/* Details Panel */}
                {detailsVisible && (
                    <div className="p-6 bg-gray-50 border-t border-gray-200">
                        <div className="mb-2">
                            <strong>Necessary Cookies</strong>
                            <p className="text-sm text-gray-600">
                                These cookies are necessary for the website to function and
                                cannot be switched off in our system. They are set in response
                                to actions made by you, such as logging in, filling forms, or
                                setting your privacy preferences.
                            </p>
                        </div>
                        <div className="mb-2">
                            <strong>Functional Cookies</strong>
                            <p className="text-sm text-gray-600">
                                These cookies enable the website to provide enhanced
                                functionality and personalization. They may be set by us or by
                                third-party providers whose services we have added to our pages.
                            </p>
                        </div>
                        <div className="mb-2">
                            <strong>Analytical Cookies</strong>
                            <p className="text-sm text-gray-600">
                                These cookies allow us to count visits and traffic sources so we
                                can measure and improve the performance of our site. All
                                information these cookies collect is aggregated and therefore
                                anonymous.
                            </p>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};