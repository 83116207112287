import { SUBSCRIPTION_TYPE, SubscriptionType, UserSubscription } from '../user-subscription';
import { api } from './base';

export interface UserSubscriptionStatusResponse {
    showAccountSubscriptionModal?: boolean;
    showGptSubscriptionModal?: boolean;
    subscriptionType: SubscriptionType;
    data?: any;
}

export interface UserSubscriptionResponse {
    userSubscription: UserSubscription;
    subscriptionType?: SubscriptionType;
}

export const userSubscriptionQuery = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserGptSubscription: builder.mutation<UserSubscriptionStatusResponse, void>({
            query: () => ({
                url: `/user-subscription/gpt-3.5-turbo`,
                method: "POST"
            })
        }),
        getUserSubscription: builder.mutation<UserSubscriptionResponse, SubscriptionType | void>({
            query: (subscriptionType) => ({
                url: `/user-subscription/get-user-subscription`,
                method: "POST",
                body: {
                    ...subscriptionType
                }
            })
        }),
        getUserSubscriptionTypeInfo: builder.mutation<UserSubscriptionResponse, SUBSCRIPTION_TYPE>({
            query: (subscriptionType) => ({
                url: `/user-subscription/get-subscription-type-info`,
                method: "POST",
                body: {
                    subscriptionType
                }
            })
        }),
        getGptPurchaseSubscriptionType: builder.mutation<SubscriptionType, void>({
            query: (subscriptionType) => ({
                url: `/user-subscription/get-gpt-purchase-subscription-type`,
                method: "GET"
            })
        })  
    })
})

export const { useGetUserGptSubscriptionMutation,  useGetUserSubscriptionTypeInfoMutation, useGetUserSubscriptionMutation, useGetGptPurchaseSubscriptionTypeMutation } = userSubscriptionQuery;