import { UserFormData } from '../../store/models/formData';
import { Feedback } from '../dtos/feedback-element.dto';
import { FeedbackItem, ScoreObject } from '../resume-score-feedbacks';
import { SubscriptionType } from '../user-subscription';
import { api } from './base';

export interface JdToResumeParam {
    jobDescription?: string;
    document_id?: string;
    create_copy?: boolean;
    coverLettToName?: string;
    coverLettToAddress?: string;
    email?: string;
    firstName?: string;
    appTemplateId: string;
    coverLettToEmail?: string;
    address?: string;
    phone?: string;
    professionalTitle?: string
}

export interface JdToResumeResponse extends GptApiResponse {
    document_id: string;
    formData: UserFormData;
    title: string;
    selectedTemplateId: string;
}

export interface ResumeScoreParam {
    jobDescription: string;
    documentId: string;
    jobCode?: string;
}

export interface ImproveResumeScoreParam {
    formData?: UserFormData;
    jobDescription: string;
    resumeImprovementSuggection: string;
    documentId: string;
    createNewCopy: boolean
}

export interface ResumeImprovementResponse extends GptApiResponse {
    document_id: string;
    formData: UserFormData;
    title: string;
    selectedTemplateId: string;
}


export interface ResumeScoreResponse {
    score: ScoreObject,
    feedBacks: FeedbackItem[]
}

export interface GptApiResponse {
    showSubscriptionModal?: boolean;
    showAccountSubscriptionModal?: boolean;
    success?: boolean;
    message?: string;
    data?: any;
    subscriptionType?: SubscriptionType;
    gpt_subscription?: any;
}

export interface FeedBackParam {
    jobDescription: string,
    question: string,
    score?: number,
    documentId: string
}

export interface FeedbackResponse extends GptApiResponse {
    feedback: string
}

export interface AiFeedBackResponse extends GptApiResponse {
    data: string[]
}


export interface AiFieldConfig {
    id: string;
    formSectionName: string; // formdata[workHistory],
    type: 'inject' | 'prompt' | 'suggection' | 'warning' | 'info' | 'positive-feedback' | 'list' | 'none';
    valueToInject?: Object;
    promptToAi: string;
    promptValueIndex?: number; // index 
    promptPropertyName?: string; // degree or achivements
    label: string;
    positiveFeedbacks?: string[];
    list?: string[];
    status?: string;
}

export class ResumeModifyWorkExperience {
    message: string;
    quantifiableAchievements?: boolean;
    suggection_id: string;
    feedbackForPropertyName: string;  // achievements
    sectionName: string;  // workHistory
    aiFieldSuggections: AiFieldConfig[];
    sectionDataIndex: number;  // 0
    sectionKeyPropertyName: string; // title
    sectionKey: string;  // workhistory[0][title]  + imndex
}

export interface ResumeScanResponse {
    success: true;
    data: Feedback
}

export const aiServicesQuery = api.injectEndpoints({
    endpoints: (builder) => ({
        generateResumeFromJd: builder.mutation<JdToResumeResponse, JdToResumeParam>({
            query: (data) => ({
                url: `/ai-services/resume-from-jd`,
                method: "POST",
                body: data
            })
        }),
        resumeScore: builder.mutation<ResumeScanResponse, ResumeScoreParam>({
            query: (data) => ({
                url: `/resume-score`,
                method: "POST",
                body: data
            })
        }),
        improveResume: builder.mutation<ResumeImprovementResponse, ImproveResumeScoreParam>({
            query: (data) => ({
                url: `/resume-score/improve`,
                method: "POST",
                body: data
            })
        }),
        generateCoverLetterFromJd: builder.mutation<JdToResumeResponse, JdToResumeParam>({
            query: (data) => ({
                url: `/ai-services/coverletter-from-jd`,
                method: "POST",
                body: data
            })
        }),
        generateFeedBack: builder.mutation<FeedbackResponse, FeedBackParam>({
            query: (data) => ({
                url: `/resume-score/feedBackOption`,
                method: "POST",
                body: data
            })
        }),
        generateAiFeedback: builder.mutation<AiFeedBackResponse, ResumeModifyWorkExperience>({
            query: (data) => ({
                url: `/resume-score/generate-feedback`,
                method: "POST",
                body: data
            })
        })
    })
})

export const {
    useGenerateResumeFromJdMutation,
    useImproveResumeMutation,
    useResumeScoreMutation,
    useGenerateCoverLetterFromJdMutation,
    useGenerateFeedBackMutation,
    useGenerateAiFeedbackMutation,
} = aiServicesQuery;