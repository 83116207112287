import { createSlice } from "@reduxjs/toolkit";
import { AppTemplate, TemplateColorSet, templateQuery } from "./queries/templates-query";

interface DocumentPageSize {
    width: number,
    height: number
}

export interface DocumentType {
    label: string;
    pageSize: DocumentPageSize | string;
    key: string;
    icon: string;
}

export interface DoucmentSlice {
    appTemplates: AppTemplate[];
    colorSets: TemplateColorSet[];
    templateVersion: string
}

const initialState: DoucmentSlice = {
    appTemplates: [],
    colorSets: [],
    templateVersion: ''
};

const appTemplate = createSlice({
    name: "template",
    initialState: initialState,
    reducers: {
        setDefaultTemplates: (state) => {
            state.appTemplates = [
                {
                    "category": "resume",
                    "id": "1",
                    "title": "Basic",
                    "description": "resume",
                    "defaultColorSetId": "1",
                    "supportingColorSetIds": [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5"
                    ]
                },
                {
                    "category": "resume",
                    "id": "2",
                    "description": "resume",
                    "title": "Easy",
                    "defaultColorSetId": "1",
                    "supportingColorSetIds": [
                        "1",
                        "3",
                        "4",
                        "6"
                    ]
                },
                {
                    "category": "cover-letter",
                    "id": "3",
                    "title": "Essential",
                    "description": "cover letter",
                    "defaultColorSetId": "2",
                    "supportingColorSetIds": [
                        "2"
                    ]
                },
                {
                    "category": "resume",
                    "id": "4",
                    "title": "TechPro",
                    "description": "resume",
                    "defaultColorSetId": "1",
                    "supportingColorSetIds": [
                        "2",
                        "3",
                        "4"
                    ]
                },
                {
                    "category": "resume",
                    "id": "5",
                    "title": "Modern",
                    "description": "resume",
                    "defaultColorSetId": "1",
                    "supportingColorSetIds": [
                        "1",
                        "3",
                        "4"
                    ]
                },
                {
                    "category": "cover-letter",
                    "id": "6",
                    "title": "Clear Connect",
                    "description": "cover letter",
                    "defaultColorSetId": "2",
                    "supportingColorSetIds": [
                        "2"
                    ]
                },
                {
                    "category": "resume",
                    "id": "7",
                    "title": "Two Column",
                    "description": "resume",
                    "defaultColorSetId": "1",
                    "supportingColorSetIds": [
                        "2",
                        "3",
                        "4"
                    ]
                },
                {
                    "category": "cover-letter",
                    "id": "8",
                    "title": "Professional Pitch",
                    "description": "cover letter",
                    "defaultColorSetId": "8",
                    "supportingColorSetIds": [
                        "10"
                    ]
                },
                {
                    "category": "cover-letter",
                    "id": "9",
                    "title": "Bold Blue",
                    "description": "cover letter",
                    "defaultColorSetId": "10",
                    "supportingColorSetIds": [
                        "10"
                    ]
                },
                {
                    "category": "resume",
                    "id": "10",
                    "title": "Classic",
                    "description": "resume",
                    "defaultColorSetId": "1",
                    "supportingColorSetIds": [
                        "1"
                    ]
                },
                {
                    "category": "resume",
                    "id": "11",
                    "title": "Modern",
                    "description": "resume",
                    "defaultColorSetId": "1",
                    "supportingColorSetIds": [
                        "1"
                    ]
                },
                {
                    "category": "resume",
                    "id": "12",
                    "title": "Minimal",
                    "description": "short resume",
                    "defaultColorSetId": "8",
                    "supportingColorSetIds": [
                        "8",
                        "9"
                    ]
                }
            ]
        }
    },
    extraReducers: (builder) => {
        // builder.addMatcher(templateQuery.endpoints.getAppTemplates.matchFulfilled, (state, action) => {
        //     if (action.payload.reSketch || !state.templateVersion) {
        //         state.appTemplates = action.payload.appTemplates;
        //         state.templateVersion = action.payload.templateVersion;
        //         state.colorSets = state.colorSets = action.payload.colorSets;
        //     }
        // })
    }
});

export const template = appTemplate.reducer;
export const { setDefaultTemplates } = appTemplate.actions;