import { api } from './base';

export interface JobPostParams {
    organization?: string;
    job_title: string;
    job_description: string;
    job_location: string;
    job_type: string[];
    posting_date: Date;
    closing_date: Date;
    salary_range: number[];
    is_active: boolean;
    experienceLevel?: string;
    country: string;
    _id?: string;
    meta_data?: any
}

export interface JobPostResponse {
    id: string;
    organization?: string;
    job_title: string;
    job_description: string;
    experienceLevel?: string;
    job_location: string;
    job_type: string[];
    posting_date: Date;
    closing_date: Date;
    salary_range: number[];
    skills: string[];
    is_active: boolean;
    country: string;
    _id?: string;
    meta_data?: any;
}

export interface JobApplicationResponse {
    id: string;
    organization?: string;
    job_title: string;
    job_description: string;
    experienceLevel?: string;
    job_location: string;
    job_type: string[];
    posting_date: Date;
    closing_date: Date;
    salary_range: number[];
    skills: string[];
    is_active: boolean;
    country: string;
    _id?: string;
    meta_data?: any;
    firstName?: string;
    phone?: string;
    email?: string;
}


interface JobPostDeleteParam {
    _id: string;
}

export interface JobApplyDtoParameter {
    job_id: string;
    document_id: string;
    documentId: string;
    cover_letter_id?: string;
    email?: string;
    phone?: string;
    name?: string;
}

export interface JobApplyDtoResponse {
    id: string;
    success: boolean
}

export const jobPostQuery = api.injectEndpoints({
    endpoints: (builder) => ({
        createJobPost: builder.mutation<JobPostResponse, JobPostParams>({
            query: (data) => ({
                url: `/job-posts/create-recruiter-job`,
                method: "POST",
                body: data
            })
        }),
        applyJobPost: builder.mutation<JobApplyDtoResponse, JobApplyDtoParameter>({
            query: (data) => ({
                url: `/job-posts/apply-job`,
                method: "POST",
                body: data
            })
        }),
        getJobPosts: builder.mutation<JobPostResponse[], void>({
            query: () => ({
                url: `/job-posts/get_jobs`,
                method: "POST"
            })
        }),
        deleteJobPost: builder.mutation<JobPostResponse, JobPostDeleteParam>({
            query: (data) => ({
                url: `/job-posts/delete`,
                method: "POST",
                body: data
            })
        }),
        findJobPost: builder.mutation<JobPostResponse, JobPostDeleteParam>({
            query: (data) => ({
                url: `/job-posts/find_job`,
                method: "POST",
                body: data
            })
        }),
        searchJobPost: builder.mutation<JobPostResponse[], JobPostParams>({
            query: (data) => ({
                url: `/job-posts/search`,
                method: "POST",
                body: data
            })
        }),
        getAppliedJobs: builder.mutation<JobApplicationResponse[], void>({
            query: (data) => ({
                url: `/job-posts/get-applied-jobs`,
                method: "POST",
                body: data
            })
        }),
        revokeJobApply: builder.mutation<void, string>({
            query: (job_id) => ({
                url: `/job-posts/revoke-job`,
                method: "POST",
                body: {
                    job_id
                }
            })
        }),
    })
})

export const { useRevokeJobApplyMutation, useGetAppliedJobsMutation, useCreateJobPostMutation, useDeleteJobPostMutation, useGetJobPostsMutation, useFindJobPostMutation, useSearchJobPostMutation, useApplyJobPostMutation } = jobPostQuery;